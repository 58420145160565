import React, { useState } from 'react'
import { AchivementWrapper } from './Styled'
import { serviceMaintaneData } from '../../data/Data'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, SpanStyled, TextStyled } from '../../Styled/Elements'
import { FooterArrow } from '../../Styled/AllImages'

const ServiceMaintane = () => {
    const { t } = useTranslation();
    const [activeItem, setActiveItem] = useState(0);
    const toggleItem = (index) => {
        // setActiveItem(activeItem === index ? null : index);
        setActiveItem(activeItem === index ? activeItem : index);
    };

    return (
        <AchivementWrapper id='services' className='common-space'>
            <Container>
                <Row className='item-container'>
                    <Col lg={3} md={12}>
                        {
                            serviceMaintaneData.map((list, index) => (
                                <Box>
                                    <Box key={index} className={`item-box border-bottom-line  ${activeItem === index ? 'active-item-box' : ''}`} onClick={() => toggleItem(index)}>
                                        <HeadingStyled size='16px'> {t(list.heading)} </HeadingStyled>
                                    </Box>
                                    {activeItem === index && (
                                        <Box className='service-solution-list mobile-view'>
                                            <Box>
                                                <HeadingStyled className='gradient'> {t(list.heading)} </HeadingStyled>
                                                <TextStyled className='my-2' size='16px'>{t(list.text)}</TextStyled>
                                            </Box>
                                            <Box className=' service-list-container my-4'>
                                                <Box>
                                                    {list.list.map((item, itemIndex) => (
                                                        <Box key={itemIndex} className='d-flex align-items-center gap-3 my-3'>
                                                            <SpanStyled>
                                                                <FooterArrow />
                                                            </SpanStyled>
                                                            <TextStyled className='mb-0'>{t(item)}</TextStyled>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            ))
                        }

                    </Col>
                    <Col className='border-right' lg={9} md={12}>
                        <Box className='service-solution-data-container desktop-view' >
                            {serviceMaintaneData.map((list, index) => (
                                <Box key={index} className={`${activeItem === index ? '' : ''}`} >
                                    {activeItem === index && (
                                        <Box className='service-solution-list'>
                                            <Box>
                                                <HeadingStyled className='gradient'> {t(list.heading)} </HeadingStyled>
                                                <TextStyled className='my-2' size='16px'>{t(list.text)}</TextStyled>
                                            </Box>
                                            <Box className=' service-list-container my-4'>
                                                <Box>
                                                    {list.list.map((item, itemIndex) => (
                                                        <Box key={itemIndex} className='d-flex align-items-center gap-3 my-3'>
                                                            <SpanStyled>
                                                                <FooterArrow />
                                                            </SpanStyled>
                                                            <TextStyled className='mb-0'>{t(item)}</TextStyled>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </Col>
                    {/* {serviceMaintaneData.map((list, index) => {
                        return (
                            <>

                            </>
                        )
                    })} */}
                </Row>
            </Container>
        </AchivementWrapper >
    )
}

export default ServiceMaintane