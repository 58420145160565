import i18n from './Languages/INEXT';
import React, { useEffect } from 'react'
import Header from './components/header/Header';
import { Box } from './components/Styled/Elements';
import { privateRoute } from './route/PrivateRoute';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Footer from './components/footer/Footer';
import FooterAnimated from './components/footerAnimated/FooterAnimated';
import HeaderTwo from './components/header/HeaderTwo';

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, behaviour: 'smooth' })
  }, [location]);
  const [cookies, setCookie] = useCookies(['lang']);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCookie('lang', lng, { path: '/' }); // Set cookie with the language preference
  };

  const locationTwo = useLocation();
  // Check if the current path matches '/management-consultation' or '/human-resources' or '/training-rehabilitation'
  const isHeaderTwoPage = locationTwo.pathname === '/management-consultation' || locationTwo.pathname === '/human-resources' || locationTwo.pathname === '/training-rehabilitation';
  

  useEffect(() => {
    // Check if the current path matches '/management-consultation' or '/human-resources' or '/training-rehabilitation'
    const isHeaderTwoPage = location.pathname === '/management-consultation' || location.pathname === '/human-resources' || locationTwo.pathname === '/training-rehabilitation';
    
    // Add or remove class on the body based on the condition
    if (isHeaderTwoPage) {
      document.body.classList.add('secHeader');
    } else {
      document.body.classList.remove('secHeader');
    }

    // Clean up by removing the class when the component unmounts or when the path changes
    return () => {
      document.body.classList.remove('secHeader');
    };
  }, [location.pathname]); // Re-run the effect when the path changes



  return (
    <>
      <Box className='main-artical'>
        
        {isHeaderTwoPage ? <HeaderTwo /> : <Header />}
        <Routes>
          {privateRoute.map((list, key) => (
            <Route to={key} {...list} />
          ))}
        </Routes>
        <FooterAnimated />
        <Footer />
      </Box>
    </>
  );
}

export default App;
