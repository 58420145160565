import styled from "styled-components";

export const FooterWrapper = styled.footer`
// padding:40px 0;
.footer-row{
    padding-top:40px;
    // padding-bottom:40px;
}
.footer-row > *{
    padding-bottom: 40px;
}
background-color: var(--text);
background-repeat: no-repeat;
    background-position: right 5px;
.footer-list{
    display: flex;
    flex-direction: column;
    align-items: start;
    // justify-content: center;
    // gap: 5px;
    & a{
        color: var(--white);
        font-family: var(--semibold);
        &:hover{
            // color: var(--text);
        }
    }
    
}
.footer-list a{
    color:var(--white);
    padding-bottom: 8px;
    position: relative;
    font-size:16px;
    font-family: var(--regular);
    font-weight: 400;
    // text-transform: uppercase;
    &:hover, &.active, &:focus{
        // background: var(--lightgreen);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        color:var(--white);
        font-family: var(--semibold);
        font-weight: 400;
        &::before, &.active::before{
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 15px;
            height: 3px;
            background: var(--lightgreen);
        }
    }
}
.footer-revised{
    position:relative;
    &::before{
        content:' ';
        position:absolute;
        top:0;
        right:0;
        left:-20px;
        margin:auto;
        width:101%;
        height:1px;
        background-color:#8C919D;
    }
    padding:25px 25px 25px 25px;
    // border-top:1px solid grey;
}
.fowl{
    cursor: pointer;
}
.footer-contact-us{
    &>div{
        margin:15px 0;
    }
    & .contact-text{
        color:var(--white);
        
    }
    & svg{
        [fill]{
            fill:var(--white);
        }
    }
} 
.footer-p{
    padding-left:50px;
}
.footer-op{
    opacity:0.7;
}
.footerSocial{
    padding-left: 36px;
  }
`