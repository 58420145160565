import React from 'react'
import ContactForm from './ContactForm'
import Footer from '../../footer/Footer'
import { ContactWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { TextWithIcon } from '../../Styled/CustomElements'
import { Box, HeadingStyled, LazyImage, MainHeading, Span, } from '../../Styled/Elements'
import { ContactBg, ContactImg, Email, Instagram, Linkdin, Linkedin, Location, Phone, Tiktok, Twitter, Youtube } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'

const Contact = () => {
  const Map = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.3357822098665!2d46.63897367444418!3d24.81818804694953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee40eefcb6a9d%3A0xbc4308498520f827!2sAnas%20Ibn%20Malik%20Rd%2C%20Riyadh%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1715076151080!5m2!1sen!2s"
  const { t } = useTranslation();
  const socialIcon = [
    {
      img: <Twitter />,
      link: '#!',
    },

    {
      img: <Instagram />,
      link: '#!',
    },
    {
      img: <Linkedin />,
      link: '#!',
    },
  ];
  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default action of the link
  };

  return (
    <ContactWrapper id='contact' className='common-space' style={{ background: `var(--white)` }} >
      <Container>
        <Box className='containerContact'>
          <Row>
            <Col className='contact-Us' style={{ background: `var(--bg)` }}>
              <Box className='contact-wrapper'  >
                <Box className='contect-with-us'>
                  <ContactForm />
                </Box>
                <Box className='contect-with-you'>
                  <Box className='content-heading contact-heading'>
                    <HeadingStyled size='24px' className='main-heading gradient'> {t('connect')} </HeadingStyled>
                  </Box>
                  <Box className='d-flex flex-column gap-3'>
                    <Box className='contact-info'>
                      <TextWithIcon headingSize='14px' className='contact-text' icon={Phone} title='+966558866745' />
                    </Box>
                    <Box className='contact-info'>
                      <TextWithIcon headingSize='14px' className='contact-text' icon={Email} title='info@nabd-solutions.com.sa' />
                    </Box>

                    <Box className='contact-info'>
                      <TextWithIcon headingSize='14px' className='contact-text' icon={Location} title={t('location')} />
                    </Box>
                    <Box className='follow'>
                      <Box className='d-flex align-items-center gap-3'>
                        {
                          socialIcon.map((list, key) => (
                            <Box key={key} className='contact-links'>
                              <a href={list.link} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                                {list.img}
                              </a>
                            </Box>
                          ))
                        }
                      </Box>
                    </Box>
                    <Box className='contact-box'>
                      <iframe src={Map} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Col>
          </Row>
        </Box>
      </Container>
    </ContactWrapper >
  )
}

export default Contact