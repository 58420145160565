import React, { useState } from 'react'
import { AboutWrapper, HumanWrapper, ManageWrapper, PackagesWrapper, TrainingWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { ContactButton, OurPackages } from '../../Styled/CustomElements'
import { Box, HeadingStyled, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { AboutBg, AboutBg1, AboutImg1, AboutImg2, BTNICON, FooterArrow } from '../../Styled/AllImages'
import { TrainingRehabilitationData, manageConsultData, serviceMaintaneData } from '../../data/Data'



const TrainingRehabilitation = () => {
    const { t, i18n } = useTranslation();

    

    
    return (
        <TrainingWrapper className='common-space' >
            <Container >
                <Row className='item-container'>
                    <Col className='border-right' lg={9} md={12}>
                        <Box className='service-solution-data-container desktop-view' >
                            {TrainingRehabilitationData.map((list, index) => (
                                <Box key={index} className='' >
                                    
                                    <Box className='service-solution-list'>
                                        <Box>
                                            <HeadingStyled className='gradient'> {t(list.heading)} </HeadingStyled>
                                            <TextStyled className='my-2 mb-4' size='16px'>{t(list.text)}</TextStyled>
                                            
                                            <TextStyled className='my-2' size='16px'>{t(list.text2)}</TextStyled>
                                        </Box>
                                        <Box className=' service-list-container my-4'>
                                            <Box>
                                                {list.list.map((item, itemIndex) => (
                                                    <Box key={itemIndex} className='d-flex align-items-center gap-3 my-3'>
                                                        <SpanStyled>
                                                            <FooterArrow />
                                                        </SpanStyled>
                                                        <TextStyled className='mb-0'>{t(item)}</TextStyled>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Box>
                                    
                                </Box>
                            ))}
                        </Box>
                    </Col>
                    
                </Row>
                
            </Container >
        </TrainingWrapper >
    )
}

export default TrainingRehabilitation