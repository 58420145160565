import { Link } from 'react-scroll'
import { Box } from '../Styled/Elements'
import { HeaderContainer } from './Styled'
import { NavDataConfig } from '../data/Data'
import { useNavigate, Link as RouterLink, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsJustify, BsX } from "react-icons/bs"
import { Container, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { Phone, Logo, ArrowRight } from '../Styled/AllImages'
import { ButtonWithIcon } from '../Styled/CustomElements'
import ChangeButton from '../../Languages/ChangeButton'
// import { Route, Routes, useLocation, Switch, Navigate } from 'react-router-dom';
// import {  } from "react-router-dom";

const HeaderTwo = () => {
    const navigate = useNavigate()
    const WhatsApp = () => {
        const phoneNumber = '+966558866745';
        const message = 'Hello, NABD ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const { t } = useTranslation();
    const [isSticky, setIsSticky] = useState(false);
    const [toggle, setToggle] = useState(false);





    const handleToggle = () => {
        setToggle(!toggle);
        const element = document.querySelector(".nav-bar-list");
        element.classList.toggle("showToggle");
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (

        
        <HeaderContainer className={`border-bottom-line ${isSticky ? 'sticky' : ''} container-fluid`}>
            <Container>
                <Row>
                    <Box className='nav-bar'>
                        <Box className='logo' onClick={() => navigate('/')}>
                            <Logo />
                        </Box>
                        <ul className='nav-bar-list'>
                            <li>
                                <RouterLink to='/' spy={true} smooth={true} offset={-60} duration={500} activeClass="active" onClick={handleToggle}>{t('Home')}</RouterLink>
                            </li>
                            <li>
                                <RouterLink to='/' spy={true} smooth={true} offset={-60} duration={500} activeClass="" onClick={handleToggle}>{t('About')}</RouterLink>
                            </li>
                            <li className='subMenu'>
                                <Link to='services' spy={true} smooth={true} offset={-60} duration={500} activeClass="" onClick={handleToggle}>{t('Services')}</Link>
                                <ul>
                                    <li><RouterLink to='/management-consultation'>{t('Management_Consultaion')}</RouterLink></li>
                                    <li><RouterLink to='/human-resources'>{t('Human_Resources_')}</RouterLink></li>
                                    <li><RouterLink to='/training-rehabilitation'>{t('Training_Rehabilitation')}</RouterLink></li>
                                </ul>
                            </li>
                            <li>
                                <RouterLink to='/' spy={true} smooth={true} offset={-60} duration={500} activeClass="" onClick={handleToggle}>{t('Package')}</RouterLink>
                            </li>
                            <li>
                                <RouterLink to='/' spy={true} smooth={true} offset={-60} duration={500} activeClass="" onClick={handleToggle}>{t('Client')}</RouterLink>
                            </li>
                            <li>
                                <RouterLink to='/' spy={true} smooth={true} offset={-60} duration={500} activeClass="" onClick={handleToggle}>{t('contactUs')}</RouterLink>
                            </li>
                            {/* {NavDataConfig.map((item, key) => (
                                <Box key={key}>
                                    <Link to={item.to} spy={true} smooth={true} offset={-60} duration={500} activeClass="active" onClick={handleToggle}>{t(item.label)}</Link>
                                </Box>
                            ))} */}
                        </ul>
                        
                        
                        
                        <Box className='d-flex align-items-center'>
                            <ButtonWithIcon icon={ArrowRight} label={t('Book_Your')} onClick={WhatsApp} />
                            <span className="toggleBtn" onClick={handleToggle}>
                                {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
                            </span>
                        </Box>
                    </Box>
                </Row>
            </Container>
            <ChangeButton />
        </HeaderContainer>


    )
}


export default HeaderTwo
