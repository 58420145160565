import styled from 'styled-components'
import { Box, HeadingStyled, SpanStyled, TextStyled } from './Elements';
import { t } from 'i18next';


export const sliceProps = (props, sub) => {
    const { children, ...otherProps } = props;
    sub.forEach(prop => delete otherProps[prop]);
    return otherProps;
}

export const ButtonWithIcon = (props) => {
    const unwantedProps = ['icon', 'label', 'bgBtn', 'clName', 'clNameMain'];
    return (
        <ButtonWrapper {...sliceProps(props, unwantedProps)} className={`d-flex align-items-center ${props.clNameMain ? props.clNameMain : ''}`}>
            <button {...sliceProps(props, unwantedProps)} className={`header-btn ${props.clName ? props.clName : ''} ${props.bgBtn ? props.bgBtn : ''} ${!props.icon ? 'Btn-icon' : ''} `} autoComplete='false'>
                <SpanStyled size='14px'>{props.label ? props.label : ''}</SpanStyled>
                {props.icon && (
                    <props.icon width='20px' />
                )}
            </button>
        </ButtonWrapper>
    )
}

export const ContactButton = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ContactWrapper {...sliceProps(props, unwantedProps)}>
            <button {...sliceProps(props, unwantedProps)} className={`click-btn gap-3 ${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                <HeadingStyled color='var(--white)' size='14px' weight='400'>{props.label ? props.label : ''}</HeadingStyled>
                {props.icon && (
                    <props.icon width='22px' />
                )}
            </button>
        </ContactWrapper>
    )
}
export const ContactButton2 = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ContactWrapper2 {...sliceProps(props, unwantedProps)}>
            <button {...sliceProps(props, unwantedProps)} className={`click-btn gap-3 ${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                <HeadingStyled color='' size='14px' weight='400'>{props.label ? props.label : ''}</HeadingStyled>
                {props.icon && (
                    <props.icon width='22px' />
                )}
            </button>
        </ContactWrapper2>
    )
}
export const InputWithIcon = (props) => {
    const unwantedProps = ['icon', "label"];
    return (
        <InputStyledWrap className='position-relative' >
            <InputStyled {...sliceProps(props, unwantedProps)} className={`w-100 ${!props.icon ? 'no-icon' : ''}`} autoComplete='false' />
            {props.icon && (
                <props.icon width='18px' className='position-absolute input-svg' />
            )}
        </InputStyledWrap>
    )
}
export const TextWithIcon = (props) => (
    <TextWithStyledWrapper color={props.color} className={`d-flex align-items-start `}>
        <Box className='mx-2 d-flex align-items-center justify-content-center contact-svg'>
            <props.icon width='20px' height='20px' />
        </Box>
        <Box>
            <Box className='mb-0 contact-text' size={props.headingSize ? props.headingSize : '14px'}
                color={!props.alsotextColor ? 'var(--text)' : 'var(--text)'}
                dangerouslySetInnerHTML={{ __html: props.title }}>
            </Box>
            {props.desc ?
                <TextStyled className='text-uppercase' color={'var(--text)'} size='14px' >
                    {props?.desc}
                </TextStyled>
                : null}
        </Box>
    </TextWithStyledWrapper>
)
export const OurPackages = (props) => {
    const WhatsApp = () => {
        const phoneNumber = '+966558866745';
        const message = 'Hello, NABD ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const unwantedProps = ['bgBtns', 'liClass', 'portClass', 'pkgListing', 'clNameCol', 'pkgHeading', 'hGradient'];
    return (
        <OurPackagesWrapper {...sliceProps(props, unwantedProps)} className={`packagesCol ${props.clNameCol ? props.clNameCol : ''}`}>
            <HeadingStyled className={`main-heading mb-3 txtGradient ${props.hGradient} `} size="32px">{props.pkgHeading}</HeadingStyled>
            <ButtonWithIcon label={t('Request')} bgBtn={props.bgBtns} clName="pkgBtn no-border mb-4 w-100 text-center justify-content-center" clNameMain="justify-content-center" onClick={WhatsApp}  />
            {props.pkgListing}
        </OurPackagesWrapper>
    )
}

const OurPackagesWrapper = styled.div`
    border-radius: 16px;
    padding: 30px 20px 30px 20px;
    height:100%;
`
const TextWithStyledWrapper = styled.div`
  svg {
      [stroke] {
          stroke: ${({ color }) => color ? color : ''};
      }
  }
`
export const InputStyled = styled.input`
    background:var(--bgColor);
    border: none;
    border-radius: 8px;
    height: 43px;
    // color: var(--textGrey);
    &:not(.no-icon) {
        padding-left: 50px;
    }
    &.no-icon {
        padding-left: 18px;
    }
    padding-right: 18px;
    outline: none;
    &,&::placeholder {
        font-size: 14px;
        color: var(--textPalceholder);
    }
`
export const InputStyledWrap = styled.div`
    border-radius: 8px;
    background: var(--white);
    svg {
    left: 13px;
    top: 12px;
    height: 20px;
    }
`


export const ContactWrapper = styled.span`
button{
    display: flex;
    align-items: center;
    justify-content:${props => props.content ? props.content : ''};
    gap: 6px;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    background:var(--gradient);
    box-shadow: 0px 3px 4px 0px rgb(108 107 107 / 25%);
    -webkit-box-shadow: 0px 3px 4px 0px rgb(108 107 107 / 25%);
    -moz-box-shadow: 0px 3px 4px 0px rgb(108 107 107 / 25%);
    transition: all 0.4s ease-in-out;
    &>div{
        // font-family:var(--medium);
    }
     svg{
        [fill]{
            transition: all 0.4s ease-in-out;
        }
    }
    &:hover{
        background: var(--gradient);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        color: var(--white);
        box-shadow: 0px 3px 4px 0px rgb(108 107 107 / 25%);
        -webkit-box-shadow:0px 3px 4px 0px rgb(108 107 107 / 25%);
        -moz-box-shadow: 0px 3px 4px 0px rgb(108 107 107 / 25%);
        &>div{
            font-family:var(--medium);
        }
        svg{
            [fill]{
                fill:var(--themeColor);
            }
            [stroke]{
                stroke:var(--themeColor);
            }
        }
    }
}

    
    
`
export const ContactWrapper2 = styled.span`

button{
    display: flex;
    align-items: center;
    justify-content:${props => props.content ? props.content : ''};
    gap: 6px;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    background:var(--white);
    transition: all 0.4s ease-in-out;
    box-shadow: 0px 3px 4px 0px rgb(108 107 107 / 25%);
        -webkit-box-shadow:0px 3px 4px 0px rgb(108 107 107 / 25%);
        -moz-box-shadow: 0px 3px 4px 0px rgb(108 107 107 / 25%);
    &>div{
        // font-family:var(--medium);
        background: var(--gradient);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    }
     svg{
         transition: all 0.4s ease-in-out;
        [fill]{
            fill:var(--themeColor);
        }
        [stroke]{
            stroke:var(--themeColor);
        }
    }
    &:hover{
        background: var(--gradient);
        box-shadow: 0px 3px 4px 0px rgb(108 107 107 / 25%);
        -webkit-box-shadow:0px 3px 4px 0px rgb(108 107 107 / 25%);
        -moz-box-shadow: 0px 3px 4px 0px rgb(108 107 107 / 25%);

        & >div{
            font-family:var(--medium);
            background: var(--white);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
        }
        svg{
            [fill]{
                fill:var(--white);
            }
            [stroke]{
                stroke:var(--white);
            }
        }
    }
}
    
    
`
export const ButtonWrapper = styled.div`
button,
button::after {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

button {
    background: none;
    border: 1px solid var(--white);
    border-radius: 8px;
    color: var(--white);
    display: flex;
    align-items: center;
    gap:8px;
    font-size: 14px;
    padding: 10px 14px;
    position: relative;
    span{
        font-family: var(--medium);
    } 
}

button::before,
button::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--themeColor);
    box-shadow: 1px 2px 3px #7271719e;
}
button::before{
    background: transparent;
}

button:hover {
    border: 1px solid var(--themeColor);
    color: var(--white);
}
.header-btn svg {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    [fill] {
        fill: var(--white);
    }
}

  
`