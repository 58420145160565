import styled from "styled-components";

export const AboutWrapper = styled.section`

    width: 100%;
    height: 100%;

    .about-content{
        width:100%;
        position: relative;

    }

`