import HumanResources from "../components/sections/humanResources/HumanResources";
import ManageConsultation from "../components/sections/manageConsult/ManagementConsultation";
import TrainingRehabilitation from "../components/sections/trainingRehabilitation/TrainingRehabilitation";
import Index from "./Index";

export const privateRoute = [
    {
        path: "/",
        element: <Index />,
    },
    {
        path: "/management-consultation",
        element: <ManageConsultation />,
    },
    {
        path: "/human-resources",
        element: <HumanResources />,
    },
    {
        path: "/training-rehabilitation",
        element: <TrainingRehabilitation />,
    },
    
]