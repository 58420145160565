import React from 'react'
import { ClientWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import Contact from '../contact/Contact'
import { ClientData } from '../../data/Data'
import { Ceo } from '../../Styled/AllImages'

const Client = () => {
    const { t } = useTranslation();

    return (
        <ClientWrapper id='Client' style={{ backgroundColor: `var(--bg)` }} >
            <Container className='common-space' >
                <Row>
                    <Col>
                        <Box className='d-flex align-items-center justify-content-center mb-lg-5 mb-4'>
                            <MainHeading className='gradient main-heading-client' color='var(--text)'>{t('Our_Client')}</MainHeading>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Box className='client-wrap'>
                            {
                                ClientData.map((list, key) => (
                                    <Box key={key} className='client-content'>
                                        {list.logo}
                                    </Box>
                                ))
                            }

                        </Box>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='Ceo'>
                    <Col lg={8} md={12}>
                        <Box>
                            <HeadingStyled size='40px' className='gradient mb-3'> {t('CEO_Message')} </HeadingStyled>
                            <TextStyled color='var(--text)'>{t('CEO_content')} </TextStyled>
                            <MainHeading size='18px' className='gradient'> {t('Ahmad')} </MainHeading>
                        </Box>
                    </Col>
                    <Col lg={4} md={12}>
                        <Box className='text-lg-start text-center mt-lg-0 mt-3'>
                            <LazyImage className='layer-mid-3' src={Ceo} />
                        </Box>
                    </Col>
                </Row>
            </Container>
        </ClientWrapper>
    )
}

export default Client