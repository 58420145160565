import styled from "styled-components";

export const PackagesWrapper = styled.section`

    width: 100%;
    height: 100%;
    .common-space{
        padding:50px 0;
    }

`
export const SuccessWrapper = styled.section`

    width: 100%;
    background-color: var(--darkColor) !important;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    .common-space{
        padding:50px 0;
    }
    .success-content{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .success-btn{
        & button{
            background: #00B2FF;
            & div{
                background: var(--white);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
            svg{
                [fill]{
                    fill:var(--white);
                }
                [stroke]{
                    stroke:var(--white);
                }
            }
            &:hover{
                background: var(--white);
                box-shadow: 0px 3px 4px 0px rgb(108 107 107 / 25%);
                -webkit-box-shadow:0px 3px 4px 0px rgb(108 107 107 / 25%);
                -moz-box-shadow: 0px 3px 4px 0px rgb(108 107 107 / 25%);
        
                & >div{
                    font-family:var(--medium);
                    background: var(--gradient);
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
                }
                svg{
                    [fill]{
                        fill:var(--themeColor);
                    }
                    [stroke]{
                        stroke:var(--themeColor);
                    }
                }
            }
        }
    }

`

