import React from 'react'
import { AboutWrapper, PackagesWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { ContactButton, OurPackages } from '../../Styled/CustomElements'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { AboutBg, AboutBg1, AboutImg1, AboutImg2, BTNICON } from '../../Styled/AllImages'

import ClassicList from './ClassicList'
import MediumList from './MediumList'
import DiamondList from './DiamondList'
import Success from './Success'


const Packages = () => {
    const { t, i18n } = useTranslation();

    const WhatsApp = () => {
        const phoneNumber = '+966558866745';
        const message = 'Hello, NABD ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <PackagesWrapper >
            <Container fluid >
                <Row>
                    <Col>
                        <Box id="Package" className='common-space about-content mb-0'>
                            <Container>
                                <Row className='my-3'>
                                    <Col sm={12}>
                                        <Box>
                                            <MainHeading className=' main-heading mb-3 gradient text-center'> {t('Our_Packages')} </MainHeading>
                                        </Box>
                                    </Col>
                                    <Col md={12} lg={4} className='mb-3'>
                                        <OurPackages pkgHeading={t('Classic_Package')} hGradient="greenGradient" bgBtns="greenGradient" pkgListing={<ClassicList />} clNameCol="packagesColClassic" />
                                    </Col>
                                    <Col md={12} lg={4} className='mb-3'>
                                        <OurPackages pkgHeading={t('Medium_Package')} hGradient="orangeGradient" bgBtns="orangeGradient" pkgListing={<MediumList />} clNameCol="packagesColMedium" />
                                    </Col>
                                    <Col md={12} lg={4} className='mb-3'>
                                        <OurPackages pkgHeading={t('Diamond_Package')} hGradient="blueGradient" bgBtns="blueGradient" pkgListing={<DiamondList />} clNameCol="packagesColDiamond" />
                                    </Col>
                                </Row>

                            </Container>
                        </Box>
                    </Col>
                </Row>
            </Container >
            {/* <Success /> */}
        </PackagesWrapper >
    )
}

export default Packages