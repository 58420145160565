import React from 'react'
import { FooterAnimatedWrapper, FooterWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, SpanStyled, TextStyled } from '../Styled/Elements'
import { Email, FootLogo, Footer1, Footer2, Footer3, FooterAnim1, FooterAnim2, FooterAnimAr1, FooterAnimAr2, FooterArrow, FooterBg, Instagram, Linkdin, Linkedin, Location, Logo, Phone, Twitter, VAT } from '../Styled/AllImages'
import { NavDataConfig } from '../data/Data'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { TextWithIcon } from '../Styled/CustomElements'

const FooterAnimated = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';

    return (
        <>
            {/* <FooterAnimatedWrapper className='container-fluid faWrapper'>
                <img src={FooterAnim1} className='faImg1' alt='ani' />
                <img src={FooterAnim2} className='faImg2' alt='ani' />
            </FooterAnimatedWrapper >
            <FooterAnimatedWrapper className='container-fluid faWrapper'>
                <img src={FooterAnimAr1} className='faImg1' alt='ani' />
                <img src={FooterAnimAr2} className='faImg2' alt='ani' />
            </FooterAnimatedWrapper > */}

            {isRTL ?
                (<FooterAnimatedWrapper className='container-fluid faWrapper '>
                    <Box className='imagesContainer'>
                        <img src={FooterAnimAr1} className='faImg1 img' alt='ani' />
                        <img src={FooterAnimAr2} className='faImg2 img fadeInClass' alt='ani' />
                    </Box>
                </FooterAnimatedWrapper >)
                :
                (<FooterAnimatedWrapper className='container-fluid faWrapper'>
                    <Box className='imagesContainer'>
                        <img src={FooterAnim1} className='faImg1 img' alt='ani' />
                        <img src={FooterAnim2} className='faImg2 img fadeInClass' alt='ani' />
                    </Box>
                </FooterAnimatedWrapper >)
            }

            {/* <div className='imagesContainer'>
                <img src={FooterAnim1} alt='' />
                <img className="fadeInClass" src={FooterAnim2} alt='' />
            </div> */}
            {/* <figure>
                <h2>Example Using div tags with background-image CSS</h2>
                <div class="imagesContainer">
                    <div class="imageDiv image1"></div>
                    <div class="imageDiv image2 fadeInClass"></div>
                </div>
            </figure> */}

        </>

    )
}

export default FooterAnimated