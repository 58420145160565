import React from 'react'
import { FooterWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, SpanStyled, TextStyled } from '../Styled/Elements'
import { Email, Footer1, Footer2, Footer3, FooterArrow, FooterBg, Instagram, Linkedin, Location, Logo, Phone, Twitter } from '../Styled/AllImages'
import { NavDataConfig, serviceCardData } from '../data/Data'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { TextWithIcon } from '../Styled/CustomElements'

const Footer = () => {
    const { t } = useTranslation();
    // const isRTL = i18n.dir() === 'rtl';
    const FowlWeb = () => {
        window.open('https://websites.fowl.sa/', '_blank');
    };
    const date_year = new Date();
    const socialIcon = [
        {
            img: <Twitter />,
            // link: 'https://twitter.com/deyamlandscape',
        },

        {
            img: <Instagram />,
            // link: 'https://www.tiktok.com/@deyamlandscape',
        },
        {
            img: <Linkedin />,
            // link: 'https://www.linkedin.com/company/deyam-landscape-real-estate',
        },
    ];
    return (
        <FooterWrapper className='container-fluid' style={{ backgroundImage: `url(${FooterBg})` }}>
            <Container>
                <Row className='footer-row'>
                    <Col lg={3} md={6} >
                        <Box className='d-flex flex-column gap-3'>
                            <Box className='footer-logo'>
                                <Logo />
                            </Box>
                            <Box>
                                <TextStyled color='var(--white)'> {t('footer_content')} </TextStyled>
                            </Box>
                        </Box>
                    </Col>
                    <Col lg={3} md={6} xs={5} >
                        <Box className='d-flex flex-column gap-3 footer-p'>
                            <HeadingStyled size='24px' color='var(--white)'>{t('Links')}</HeadingStyled>
                            <Box className='footer-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key} className='footer-nav-items d-flex gap-2'>
                                        <SpanStyled> <FooterArrow /> </SpanStyled>
                                        <Link to={item.to} spy={true} smooth={true} offset={-60} duration={500}>{t(item.label)}</Link>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Col>
                    <Col lg={3} md={6} xs={7}>
                        <Box className='d-flex flex-column gap-3 footer-p'>
                            <HeadingStyled size='24px' color='var(--white)'>{t('Choose')} </HeadingStyled>
                            <Box className='footer-list'>
                                {serviceCardData.map((item, key) => (
                                    <Box key={key} className='footer-nav-items d-flex gap-2'>
                                        <SpanStyled> <FooterArrow /> </SpanStyled>
                                        <Link to={item.to} spy={true} smooth={true} offset={-60} duration={500}>{t(item.heading)}</Link>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Col>
                    <Col lg={3} md={6} >
                        <Box className='d-flex flex-column gap-3 footer-p'>
                            <HeadingStyled size='24px' color='var(--white)'> {t("Contact_Us")} </HeadingStyled>
                            <Box className='footer-contact-us'>
                                <TextWithIcon headingSize='14px' className='contact-text' icon={Phone} title='+966558866745 ' />
                                <TextWithIcon headingSize='14px' className='contact-text' icon={Email} title='info@nabd-solutions.com.sa' />
                                <TextWithIcon headingSize='14px' className='contact-text' icon={Location} title={t("location")} />
                            </Box>
                            <Box className='d-flex align-items-center gap-3 footerSocial'>
                                {
                                    socialIcon.map((list, key) => (
                                        <Box key={key} className='contact-links'>
                                            <a href={list.link} target="_blank" rel="noopener noreferrer">
                                                {list.img}
                                            </a>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Col>
                </Row>
                <Row className='footer-revised'>
                    <Col lg={5} sm={6}>
                        <Box className='footer-logo-secondary d-flex align-items-center gap-3'>
                            <LazyImage src={Footer1} />
                            <LazyImage src={Footer2} />
                            <LazyImage src={Footer3} />
                        </Box>
                    </Col>
                    <Col lg={6} sm={6}>
                        <Box>
                            <SpanStyled className='footer-op' size='13px' color='var(--white)' transform='capitalize'>{t('AllRightReserved')} &copy; {date_year.getFullYear()} </SpanStyled>
                            <SpanStyled className='footer-op' size='13px' color='var(--white)' transform='capitalize'>
                                <SpanStyled className='footer-op fowl' size='14px' family="var(--bold)"> {t('Nabd')} </SpanStyled>
                            </SpanStyled>
                            <HeadingStyled className='footer-op' size='13px' color='var(--white)' transform='capitalize'>{t('Developed_by')}
                                <SpanStyled className='fowl footer-op' color='var(--white)' size='14px' family="var(--bold)" onClick={FowlWeb}> {t('Fowl')}</SpanStyled>
                            </HeadingStyled>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </FooterWrapper>
    )
}

export default Footer