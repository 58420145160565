import React from 'react'
import { AboutWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, TextStyled } from '../../Styled/Elements'
import ClientsSlider from './ClientsSlider'
import { AboutEn, AboutMain, ArrowRight } from '../../Styled/AllImages'
import { ButtonWithIcon, ContactButton } from '../../Styled/CustomElements'
import Success from '../packages/Success'


const About = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';

    const WhatsApp = () => {
        const phoneNumber = '+966558866745';
        const message = 'Hello, NABD ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <AboutWrapper id='about'>
            <Container fluid >
                <Row>
                    <Col>
                        <Box className='common-space about-content mb-0'>
                            <Container>
                                {/* <Row className='align-items-center my-3'>
                                    <Col md={12}>
                                        <ClientsSlider />
                                    </Col>
                                </Row> */}
                                <Row className=' my-3 pt-5'>
                                    <Col lg={6} md={12} className='abtColImg'>
                                        <Box className='text-end abtColImgInner'>

                                            {isRTL ? <LazyImage className='layer-mid-3' src={AboutMain} />
                                                : <LazyImage className='layer-mid-3' src={AboutEn} />}
                                        </Box>
                                    </Col>
                                    <Col lg={6} md={12} className='abtColContent'>
                                        <Box>
                                            <HeadingStyled className=' main-heading mb-3 gradient' size="32px" > {t('Creating')}</HeadingStyled>
                                            <TextStyled color='var(--text)'>
                                                {t('Creating_content')}
                                            </TextStyled>
                                            <ButtonWithIcon clName='gradientAB' icon={ArrowRight} label={t('Book_Your')} onClick={WhatsApp} />
                                        </Box>
                                    </Col>
                                </Row>
                            </Container>
                        </Box>
                    </Col>
                </Row>
            </Container>
            <Success />
        </AboutWrapper>
    )
}

export default About