import styled from "styled-components";
import { ServiceBg, ServiceCardBg, ServiceListBg } from "../../Styled/AllImages";


export const ServiceWrapper = styled.section`
    background-image: url(${ServiceBg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .heading-text{
        width:80%;
    }
    .service-card{
        display:flex;
        padding: 25px 0 25px  15px;
        border-radius: 10px;
        background-color: var(--white);
        background-image: url(${ServiceCardBg});
        background-position: right top;
        background-repeat: no-repeat;
        flex-direction: column;
        gap: 10px;
        gap:12px;
        margin:15px 0;
        height:168px;
        width:100%;
        &>span{
            svg{
                height:54px;
            }
        }
        & p{
            font-size:14px;
        }
    }
`
export const AchivementWrapper = styled.section`

background-color:var(--bg);
.item-container{
    background-image: url(${ServiceListBg});
    background-color: var(--white);
    background-position: right bottom;
    background-repeat: no-repeat;
  
    border-radius: 10px;
    margin: 10px 0;
}
.item-box{
    position:relative;
    color:var(--text);
    padding: 22px 8px;
    
    &.border-bottom-line{
        width:100%;
        border-bottom:1px solid #E3E9F0;
    }
    
}
.active-item-box{
    &.border-bottom-line{
        border-bottom:1px solid var(--themeColor);
    }
    & >div{
        background: var(--gradient);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        position;relative;
        &>span> svg{
            [stroke]{
                stroke:var(--yellow);
            }
        }
    }
    & .report{
        [fill]{
            fill:var(--yellow);
        }
    }
}
.service-solution-data-container{
    position: relative;
    padding: 25px;
    background-position: right bottom !important;
    background-repeat: no-repeat !important;
    
    .service-list-container{
        & svg {
            [fill]{
                fill:var(--themeColor) !important;
            }
            [stocke]{
                stocke:var(--themeColor) !important;
            }
        }
    }
    
}
.border-right{
    position: relative;

    &::before{
        content: '';
        position:absolute;
        left: 0;
        top:0;
        width:1px;
        height:100%;
        background-color:#E3E9F0;
    }
}
.mobile-view{
    display:none;
}


`