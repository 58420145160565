import styled from 'styled-components'
import { DropArrow } from '../Styled/AllImages'


export const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 110;
    width: 100%;
    padding:14px 0;
    transition: all 0.5s ease-in-out;
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        display:flex;  
        align-items: center;
        gap: 35px;
        & > li{
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .subMenu{
            position: relative;
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 12px auto;
            & > a{
                padding-right: 20px;
            }
            ul{
                position: absolute;
                border: 1px solid #fff;
                background-color: var(--themeColor);
                top: 52px;
                display: none;
                
                li{
                    display: flex;
                    width: 100%;
                    a{
                        display: flex;
                        width: 100%;
                        padding: 10px 15px 10px 15px;
                        border-bottom: 1px solid #fff;
                        font-size: 15px;
                        white-space: nowrap;
                    }   
                    &:last-child a{
                        border-bottom: none;
                    } 
                }
            }
            &:hover ul{
                display: block;
            }
        }
    }
    .nav-bar-list a{
        opacity:0.75;
        color:var(--white);
        ${'' /* padding-bottom: 12px; */}
        position: relative;
        font-size:16px;
        font-family: var(--medium);
        font-weight: 400;
        &.active, &:focus{
            color:var(--white);
            opacity:1;
            font-family: var(--semiBold);
            
        }
        &:hover{
            color:var(--white);
            opacity:1;
        }
    }
    .logo{
        cursor:pointer;
        transition: all 0.5s ease-in-out;
        svg{
            width:170px;
            transition: all 0.5s ease-in-out;
        }
    }
    &.sticky{
        background: var(--themeColor);
        box-shadow: 0px 3px 10px #001b2ec7;
        z-index: 200;
        & button::before,
        & button::after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--white);
            box-shadow: 1px 2px 3px #7271719e;
        }
        & button::before{
            background: transparent;
        }
        & button:hover {
            border: 1px solid var(--white);
            // color: var(--themColor);
            background: var(--gradient);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        & .header-btn:hover svg {
            [fill] {
                fill: var(--themeColor);
            }
            [stroke] {
                stroke: var(--themeColor);
            }
        }
        & .logo svg {
            width: 150px;
        }
    }
    .toggleBtn{
        display: none;
    }
}
`