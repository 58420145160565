import { Link } from 'react-scroll'
import React from 'react'
import { BannerWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, MainHeading, SpanStyled } from '../../Styled/Elements'
import { ArrowRight, BannerBg, DownArrow, DownArrow2, DownArrowAr, Instagram, Linkdin, Linkedin, Tiktok, Twitter, Video } from '../../Styled/AllImages'
import { ButtonWithIcon, ContactButton2 } from '../../Styled/CustomElements'

const Banner = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';

    const socialIcon = [
        {
            img: <Twitter />,
            // link: 'https://twitter.com/deyamlandscape',
        },

        {
            img: <Instagram />,
            // link: 'https://www.tiktok.com/@deyamlandscape',
        },
        {
            img: <Linkedin />,
            // link: 'https://www.linkedin.com/company/deyam-landscape-real-estate',
        },

    ];
    const WhatsApp = () => {
        const phoneNumber = '+966558866745';
        const message = 'Hello, NABD ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <BannerWrapper className='' id='home'>
            <Box className="headerOverlay"></Box>
            <Box className='BannerVideoWrapper'>
                <video autoPlay muted loop>
                    <source src={Video} type="video/webm" />
                </video>
            </Box>
            
            <Container>
                <Row>
                    <Col lg={5} md={12} className='position-relative'>
                        <Box className='banner-content-wrapper'>
                            <MainHeading className='main-heading ' color='var(--white)'> {t('banner')} </MainHeading>
                            <SpanStyled className='white-btn'>
                                <ButtonWithIcon clName='whitegradient' icon={ArrowRight} label={t('Book_Your')} onClick={WhatsApp} />
                            </SpanStyled>
                        </Box>
                    </Col>
                </Row>
                <Box className='down-btn'>
                    <Link to='about' spy={true} smooth={true} offset={-40} duration={500} activeClass="active">

                        {isRTL ? <DownArrowAr /> : <DownArrow2 />}
                    </Link>
                </Box>
                <Box className='social-link'>
                    {
                        socialIcon.map((list, key) => (
                            <Box key={key}>
                                <a href={list.link} target="_blank" rel="noopener noreferrer">
                                    {list.img}
                                </a>
                            </Box>
                        ))
                    }
                </Box>
            </Container>
        </BannerWrapper >
    )
}

export default Banner
