import styled from "styled-components";
import { BannerOverlay } from "../../Styled/AllImages";

export const BannerWrapper = styled.section`
    width: 100%;
    height: 100vh;
    min-height:600px;
    background-size: cover !important;
    background-position: center !important;
    display: flex;
    align-items: center;
    position: relative;
    .BannerVideoWrapper{
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    .banner-content-wrapper{
        display: flex;
        align-items: center;
        margin-top: 80px;
        flex-wrap: wrap;
        .main-heading{
            line-height: 55px;
            margin-bottom: 10px;
        }
    }
    // .headerOverlay{
    //     background-image: url(${BannerOverlay});
    //     background-size: 100% 100%;
    //     background-repeat: no-repeat;
    //     height: 100%;
    //     width: 100%;
    //     min-height: 100%;
    //     position: absolute;
    //     z-index: -1;
    //     left: 0;
    //     right: 0;
    //     opacity: .8;
    // }
    video{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 110%;
        object-fit: cover;
        z-index: 0;
        filter: brightness(0.7);
    }
    .down-btn {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 10;
        width: 50px;
        height: 50px;
        bottom: 0px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        filter: drop-shadow(0px 2px 3px #a8a8a8e0);
    }
    
    




`