import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { classicList, diamondList, mediumList } from '../../data/Data';


const DiamondList = () => {
    const { t, i18n } = useTranslation();

    return (
        <ul className='packageListWrapper'>
            {diamondList.map((item, index) => (
                <li className="unList" key={index}>
                    <SpanStyled color='var(--text)' size='16px' >
                    {t(item.listTxt)}
                    </SpanStyled>
                </li>
            ))}
        </ul>
    )
}

export default DiamondList