import React from 'react'
import { SuccessWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { ButtonWithIcon, ContactButton2 } from '../../Styled/CustomElements'
import { Box, MainHeading, SecondaryHeading, } from '../../Styled/Elements'
import { ArrowRight, SuccessBg, SuccessImg } from '../../Styled/AllImages'



const Success = () => {
    const { t, i18n } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966558866745';
        const message = 'Hello, NABD ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <SuccessWrapper style={{ background: `url(${SuccessBg})` }}>
            <Container fluid >
                <Row>
                    <Col>
                        <Box className='common-space about-content mb-0'>
                            <Container>
                                <Row>
                                    <Col md={12} lg={6} >
                                        <Box className='success-content'>
                                            <MainHeading className='main-heading ' color="var(--white)" size="36px"> {t('Fuel')}  </MainHeading>
                                            <SecondaryHeading size="24px" className='main-heading secondary-heading' color="var(--white)"> {t('Thrive')} </SecondaryHeading>
                                            <ButtonWithIcon clName="blueGradient success-btn " icon={ArrowRight} label={t('Book_Your')} onClick={WhatsApp} />
                                        </Box>
                                    </Col>
                                    <Col md={12} lg={6} className='successImgCol' >
                                        <img src={SuccessImg} alt='img' />
                                    </Col>
                                </Row>
                            </Container>
                        </Box>
                    </Col>
                </Row>
            </Container >
        </SuccessWrapper >
    )
}

export default Success