import styled from "styled-components";
import { ServiceListBg } from "../../Styled/AllImages";

/* export const HumanWrapper = styled.section`

    width: 100%;
    height: 100%;
    .common-space{
        padding:50px 0;
    }

` */

export const HumanWrapper = styled.section`

padding-top: 150px;
background-color:var(--bg);
.item-container{
    background-image: url(${ServiceListBg});
    background-color: var(--white);
    background-position: right bottom;
    background-repeat: no-repeat;
  
    border-radius: 10px;
    margin: 10px 0;
}
.item-box{
    position:relative;
    color:var(--text);
    padding: 22px 8px;
    
    &.border-bottom-line{
        width:100%;
        border-bottom:1px solid #E3E9F0;
    }
    
}
.active-item-box{
    &.border-bottom-line{
        border-bottom:1px solid var(--themeColor);
    }
    & >div{
        background: var(--gradient);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        position;relative;
        &>span> svg{
            [stroke]{
                stroke:var(--yellow);
            }
        }
    }
    & .report{
        [fill]{
            fill:var(--yellow);
        }
    }
}
.service-solution-data-container{
    position: relative;
    padding: 25px;
    background-position: right bottom !important;
    background-repeat: no-repeat !important;
    
    .service-list-container{
        & svg {
            [fill]{
                fill:var(--themeColor) !important;
            }
            [stocke]{
                stocke:var(--themeColor) !important;
            }
        }
    }
    
}
// .border-right{
//     position: relative;

//     &::before{
//         content: '';
//         position:absolute;
//         left: 0;
//         top:0;
//         width:1px;
//         height:100%;
//         background-color:#E3E9F0;
//     }
// }
.mobile-view{
    display:none;
}


`