import { Link } from "react-router-dom";
import { Client1, Client2, Client3, Client4, Client5, Client6, Client7, Client8, Client9, Client11, Client12, Client13, Client14, Client15, Client16, Client17, Client18, Excellence, Experience, Expertise, Privacy, Qualified, Quality, Solution, System } from "../Styled/AllImages";
import { LazyImage } from "../Styled/Elements";
import ManageConsultation from "../sections/manageConsult/ManagementConsultation";


export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    /* {
        to: 'choose',
        label: 'Choose',
    },
    {
        to: 'services',
        label: 'Services',
    }, */
    {
        label: 'Services',
        subMenu: [ // Add sub-menu items
        
            // { to: 'services', label: 'Main Service Page' },
            { to:"/ManageConsultation", label: 'Manage Consultation' },
            { to: 'service2', label: 'Service 2 Page' },
            // Add more sub-menu items as needed
        ],
    },
    {
        to: 'Package',
        label: 'Package',
    },
    {
        to: 'Client',
        label: 'Client',
    },

    {
        to: 'contact',
        label: 'contact Us',
    },
]

export const serviceCardData = [
    {
        icon: <Quality />,
        heading: 'Quality',
        text: 'Quality_content',
        to: 'services',
    },
    {
        icon: <Solution />,
        heading: 'Financial',
        text: 'Financial_content',
        to: 'services',
    },
    {
        icon: <System />,
        heading: 'Advanced',
        text: 'Advanced_content',
        to: 'services',
    },
    {
        icon: <Privacy />,
        heading: 'Privacy',
        text: 'Privacy_content',
        to: 'services',
    },
    {
        icon: <Expertise />,
        heading: 'Expertise',
        text: 'Expertise_content',
        to: 'services',
    },
    {
        icon: <Experience />,
        heading: 'Experience',
        text: 'Experience_content',
        to: 'services',
    },

    {
        icon: <Excellence />,
        heading: 'Excellence',
        text: 'Excellence_content',
        to: 'services',
    },
    {
        icon: <Qualified />,
        heading: 'Qualified',
        text: 'Qualified_content',
        to: 'services',
    },
]


export const serviceMaintaneData = [
    {
        heading: 'Establishment_Institutions',
        text: 'NABD_Solutions',
        list: [
            'Reservation',
            'Obtaining_trade',
            'Obtaining_necessary',
            'Opening_files',
            'Facilitating',
            'Providing',
        ]
    },
    {
        heading: 'Establishment_Foreign',
        text: 'accumulated_experience',
        list: [
            // 'Reservation of trade names.',

        ]
    },
    {
        heading: 'Ownership',
        text: 'precision',
        list: [
            'Preparation_sales',
            'Reservation',
            'Modification',
            'Issuance',
            'Opening_files',
            'Transfer_labor',
            'Facilitating_labor',
            'Provision',
            'Management',
            'Business_consultancy',
            'Qualified_team',
        ]
    },
    {
        heading: 'Legal',
        text: 'Entity',
        list: [
            'Modifying_records',
            'Resolving_issues',
            'Reserving',
            'permits',
            'Opening_entity',
            'previous_entity',
            'adjusting',
            'Facilitating_permits',
        ]
    },
    {
        heading: 'Governmental',
        text: 'Governmental_content',
        list: [
            'Providing_support',
            'problem_solving',
            'Offering_solution',
            'Supporting',
            'Resolving_issues',
            'Providingtechnical',
            'Assistingwith',
            'Offeringsupport',
        ]
    },
    {
        heading: 'Trademark',
        text: "Trademark_content",
        list: [
            'Trademark_registration',
            'Trademark_renewal',
            'trademark_data',
            'trademark_image',
            'Obtaining_certified',
            'trademark_deposit',
            'trademark_ownership',
            'trademark_licensing',
            'Trademark_opposition',
            'Trademark_removal',
        ]
    },
    {
        heading: 'Rehabilitation',
        text: 'Rehabilitation_content',
        list: [
            // 'Reservation of trade names.',

        ]
    },
];

export const ClientData = [
    {
        logo: <Link to="https://sso.qiwa.sa" target="_blank"><LazyImage src={Client11} /></Link>,
    },
    {
        logo: <Link to="https://gosi.gov.sa" target="_blank"><LazyImage src={Client12} /></Link>,
    },
    {
        logo: <Link to="https://muqeem.sa" target="_blank"><LazyImage src={Client13} /></Link>,
    },
    {
        logo: <Link to="http://absher.sa" target="_blank"><LazyImage src={Client14} /></Link>,
    },
    {
        logo: <Link to="https://mudad.com.sa" target="_blank"><LazyImage src={Client15} /></Link>,
    },
    {
        logo: <Link to="https://zatca.gov.sa" target="_blank"><LazyImage src={Client16} /></Link>,
    },
    {
        logo: <Link to="https://www.mol.gov.sa" target="_blank"><LazyImage src={Client17} /></Link>,
    },
    {
        logo: <Link to="https://eservices.taqat.sa" target="_blank"><LazyImage src={Client18} /></Link>,
    },
    

]

export const clientsItems = [
    {
        img: <LazyImage className='client-img' src={Client1} />,
    },
    {
        img: <LazyImage className='client-img' src={Client7} />,
    },
    {
        img: <LazyImage className='client-img' src={Client8} />,
    },
    {
        img: <LazyImage className='client-img' src={Client9} />,
    },
    {
        img: <LazyImage className='client-img' src={Client5} />,
    },
    {
        img: <LazyImage className='client-img' src={Client3} />,
    },

]

export const classicList = [
    {
        listTxt: 'Management_Trade',
    },
    {
        listTxt: 'Human_Resources',
    },
    {
        listTxt: 'Municipal',
    },
    {
        listTxt: 'Safety',
    },
    {
        listTxt: 'Social',
    },
    {
        listTxt: 'Affairs',
    },
    {
        listTxt: 'Zakat',
    },

]

export const mediumList = [
    {
        listTxt: 'Management_Trade',
    },
    {
        listTxt: 'Human_Resources',
    },
    {
        listTxt: 'Municipal',
    },
    {
        listTxt: 'Safety',
    },
    {
        listTxt: 'Social',
    },
    {
        listTxt: 'Affairs',
    },
    {
        listTxt: 'Zakat',
    },
    {
        listTxt: 'medical',
    },
    {
        listTxt: 'Protection',
    },
    {
        listTxt: 'Documenting',
    },


]

export const diamondList = [
    {
        listTxt: 'Management_Trade',
    },
    {
        listTxt: 'Human_Resources',
    },
    {
        listTxt: 'Municipal',
    },
    {
        listTxt: 'Safety',
    },
    {
        listTxt: 'Social',
    },
    {
        listTxt: 'Affairs',
    },
    {
        listTxt: 'Zakat',
    },
    {
        listTxt: 'medical',
    },
    {
        listTxt: 'Protection',
    },
    {
        listTxt: 'Documenting',
    },
    {
        listTxt: 'Consultation_Services',
    },
    {
        listTxt: 'Employee_Training',
    },
    {
        listTxt: 'Field_Audits',
    },


]


export const manageConsultData = [
    {
        heading: 'Management_Consulting',
        text: 'MC_Desc_1',
        text2: 'MC_Desc_2',
        /* list: [
            'Reservation',
            'Obtaining_trade',
            'Obtaining_necessary',
            'Opening_files',
            'Facilitating',
            'Providing',
        ] */
    },
    
]


export const HumanResourceData = [
    {
        heading: 'Human_Resource',
        text: 'Ins_List',
        list: [
            'List_1',
            'List_2',
            'List_3',
            'List_4',
            'List_5',
            'List_6',
            'List_7',
            'List_8',
            'List_9',
            'List_10',
            'List_11',
            'List_12',
            'List_13',
            'List_14',
        ]
    },
    
]

export const TrainingRehabilitationData = [
    {
        heading: 'Training_Rehabilitation',
        list: [
            'List_15',
            'List_16',
            'List_17',
        ]
    },
    
]