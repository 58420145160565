import React from 'react'
import { ServiceWrapper } from './Styled'
import { serviceCardData } from '../../data/Data'
import { Col, Container, Row, } from 'react-bootstrap'
import { Box, HeadingStyled, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import ServiceMaintane from './ServiceAchive'

const Service = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            {/* <ServiceWrapper id='choose' className=' common-space service-bg'>
                <Container>
                    <Row>
                        <Col>
                            <Box className='d-flex flex-column align-items-center justify-content-center gap-2 text-center'>
                                <MainHeading className='main-heading' size="32px" color='var(--white)'> {t('Consultations')} </MainHeading>
                                <TextStyled className='heading-text' color='var(--white)'> {t('Consultations_content')} </TextStyled>
                            </Box>
                        </Col>
                    </Row>
                    <Row>
                        {
                            serviceCardData.map((list, key) => (
                                <Col lg={3} md={6} sm={12}>
                                    <Box key={key} className='service-card'>
                                        <SpanStyled>
                                            {list.icon}
                                        </SpanStyled>
                                        <Box>
                                            <HeadingStyled size='18px' className='gradient'>{t(list.heading)}</HeadingStyled>
                                            <TextStyled> {t(list.text)} </TextStyled>
                                        </Box>
                                    </Box>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </ServiceWrapper > */}
            <ServiceMaintane />
        </>
    )
}

export default Service