import styled from "styled-components";

export const ClientWrapper = styled.section`

.client-wrap{
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 35px;
    padding: 30px 60px 30px 60px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}
.client-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 calc(25% - 35px);
    max-height: 90px;
    padding-left: 30px;
    padding-right: 30px;
    span{
        max-height: 100%;
        max-width: 230px;
        img{
            max-height: 106px;
        }
    }
}
.Ceo{
    // padding-top:50px;
    display:flex;
    align-items:center;
}

`
